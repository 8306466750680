import React, { useEffect } from 'react';
import { useSudokuContext } from './sudokuContext';
import moment from 'moment';

export const Timer = () => {
  let { won, pause, setPause, timeGameStarted, difficulty, setTimeGameStarted } = useSudokuContext();

  useEffect(() => {
    if (!won && !pause && timeGameStarted != null)
      setTimeout(() => tick(), 1000);
  });

  useEffect(() => {
    timeGameStarted = -1;
    setTimeGameStarted(-1);
  }, [difficulty]);

  function tick() {
    if (!pause) {
      timeGameStarted = timeGameStarted + 1;
      setTimeGameStarted(timeGameStarted);
      localStorage.setItem('timer', timeGameStarted);
    }
  }

  function getTimer() {
    if (timeGameStarted <= 0)
      return '00:00';
    let duration = moment.duration(timeGameStarted, 'seconds');
    let hours = duration.hours();
    let minutes = duration.minutes();
    let seconds = duration.seconds();
    let stringTimer = '';

    stringTimer += hours ? '' + hours + ':' : '';
    stringTimer += minutes ? (minutes < 10 ? '0' : '') + minutes + ':' : '00:';
    stringTimer += seconds < 10 ? '0' + seconds : seconds;

    return stringTimer;
  }

  function changeTimerStatus(status) {
    setPause(!status)
  }

  return (
    <>
      <div className="status__time" onClick={() => changeTimerStatus(pause)}>
        {getTimer()}
        <div className="status__button">
          {pause &&
            <svg className="h-8 w-8 text-green-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10" />
              <polygon points="10 8 16 12 10 16 10 8" />
            </svg>}
          {!pause &&
            <svg className="h-8 w-8 text-red-500" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10" />  <line x1="10" y1="15" x2="10" y2="9" />
              <line x1="14" y1="15" x2="14" y2="9" />
            </svg>
          }
        </div>
      </div>
    </>
  )
}
