import React from 'react';
import { Difficulty } from './difficulty';
import { Timer } from './timer';
import { Numbers } from './numbers';
import { Action } from './action';

/**
 * React component for the Status Section.
 */
export const StatusSection = (props) => {
  return (
    <section className="status">
      <button onClick={props.onClickNewGame} className="w-full text-center bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 text-center rounded items-center">
        New Game
      </button>
      <Difficulty onChange={props.onChange} />
      <Timer />
      <Numbers onClickNumber={(number) => props.onClickNumber(number)} />
      <div className="status__actions">
        <Action action='undo' onClickAction={props.onClickUndo} />
        <Action action='erase' onClickAction={props.onClickErase} />
        <Action action='hint' onClickAction={props.onClickHint} />
      </div>
    </section>
  )
}
