import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Sudoku } from "../components/sudoku";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Sudoku`, `Free Online Sudoku`, `Free Online Sudoku`]}
        title="Free Online Sudoku"
      />
      <Sudoku />
      <h1 className="font-bold text-3xl mt-10 mb-10 text-center">Play Free Online Sudoku</h1>
      <div className="flex mb-4">
        <div className="w-1/2 p-5 bg-gray-100">
          <h1 className="font-bold text-2xl mt-2 mb-5 text-center">Sudoku Solving Techniques</h1>
          <h2 className="font-bold text-xl mt-10 mb-5 text-center">Scanning technique</h2>
          <p className="text-base">
            The easiest way to start a Sudoku puzzle is to scan rows and columns within each 3*3 block,
            eliminating numbers or squares and finding situations where only a single number can fit into a single square.
            The scanning technique is usually enough to solve easy sudokus all the way to the end.
            You can also use the scanning technique for hard puzzles up to the point where no
            further progress can be made and more advanced solving techniques are required.
          </p>
          <h2 className="font-bold text-xl mt-10 mb-5 text-center">Analyzing technique</h2>
          <p className="text-base">
            As Sudoku puzzles get harder you will find the scanning method described above
            is not enough and more sophisticated solving techniques must be used. Hard puzzles
            require deeper logic analysis which is done with the help of pencil marks.
            Sudoku pencil marking is the process of writing small numbers inside the squares
            to identify which ones may fit in. After pencil marking the puzzle, you can analyze the results,
            identify special number combinations, and understand which numbers should be placed where.
          </p>
        </div>
        <div className="w-1/2 p-5">
          <h2 className="font-bold text-xl mb-2 text-center mb-5">What is Sudoku</h2>
          <p className="text-base">
            The idea behind sudoku is to fill the 9x9 grid with digits so that each column,
            each row, and each of the nine 3×3 block contain all of the digits from 1 to 9.
          </p>
          <h2 className="font-bold text-xl mb-2 text-center mt-5 mb-5">Daily sudoku challenges</h2>
          <p className="text-base">
            One of the best ways to improve and maintain your brain functionality is by playing
            a sudoku game each day, In Red Sudoku you can play high quality sudoku games free.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;
