import React, { useState, useEffect, useRef } from 'react';
import { GameSection } from './gameSection';
import { StatusSection } from './statusSection';
import { getUniqueSudoku } from '../util/uniqueSudoku';
import { useSudokuContext } from './sudokuContext';

export const Game = () => {
    let { numberSelected, setNumberSelected,
        gameArray, setGameArray,
        difficulty, setDifficulty,
        timeGameStarted, setTimeGameStarted,
        fastMode, setFastMode,
        cellSelected, setCellSelected,
        initArray, setInitArray,
        setWon, pause, setPause } = useSudokuContext();
    let [mistakesMode, setMistakesMode] = useState(false);
    let [history, setHistory] = useState([]);
    let [solvedArray, setSolvedArray] = useState([]);
    let [overlay, setOverlay] = useState(false);

    /**
     * Creates a new game and initializes the state variables.
     */
    function _createNewGame(changedDifficulty) {
        if (difficulty != changedDifficulty && changedDifficulty) {
            difficulty = changedDifficulty;
            timeGameStarted = 0;
            setPause(true);
        } else {
            setPause(false);
        }

        localStorage.setItem('timer', 0);
        localStorage.setItem('difficulty', difficulty);
        setNumberSelected('0');
        setTimeGameStarted(0);
        setCellSelected(-1);
        setHistory([]);
        setWon(false);

        let [temporaryInitArray, temporarySolvedArray] = getUniqueSudoku(difficulty, changedDifficulty);
        setInitArray(temporaryInitArray);
        setGameArray(temporaryInitArray);
        setSolvedArray(temporarySolvedArray);

        localStorage.setItem('temporaryInitArray', temporaryInitArray);
        localStorage.setItem('temporarySolvedArray', temporarySolvedArray);
    }

    function loadExistingGame() {
        let temporaryInitArray = localStorage.getItem('temporaryInitArray').split(',');
        let temporarySolvedArray = localStorage.getItem('temporarySolvedArray').split(',');
        let timer = parseInt(localStorage.getItem('timer'));
        let difficulty = localStorage.getItem('difficulty');

        if (!temporaryInitArray || !temporarySolvedArray) {
            _createNewGame()
        }

        setDifficulty(difficulty);
        setInitArray(temporaryInitArray);
        setGameArray(temporaryInitArray);
        setSolvedArray(temporarySolvedArray);
        setNumberSelected('0');
        setPause(true);
        setTimeGameStarted(timer);
        setCellSelected(-1);
        setHistory([]);
        setWon(false);
    }

    function _isSolved(index, value) {
        if (gameArray.every((cell, cellIndex) => {
            if (cellIndex === index)
                return value === solvedArray[cellIndex];
            else
                return cell === solvedArray[cellIndex];
        })) {
            return true;
        }
        return false;
    }

    function _fillCell(index, value) {
        if (initArray[index] === '0') {
            // Direct copy results in interesting set of problems, investigate more!
            let tempArray = gameArray.slice();
            let tempHistory = history.slice();

            // Can't use tempArray here, due to Side effect below!!
            tempHistory.push(gameArray.slice());
            setHistory(tempHistory);

            tempArray[index] = value;
            ChangeTheArray(tempArray);

            if (_isSolved(index, value)) {
                setOverlay(true);
                setWon(true);
            }
        }
    }

    function _userFillCell(index, value) {
        if (mistakesMode) {
            if (value === solvedArray[index]) {
                _fillCell(index, value);
            }
            else {
                // TODO: Flash - Mistakes not allowed in Mistakes Mode
            }
        } else {
            _fillCell(index, value);
        }
    }

    function onClickNewGame() {
        _createNewGame();
    }

    function onClickCell(indexOfArray) {
        if (fastMode && numberSelected !== '0') {
            _userFillCell(indexOfArray, numberSelected);
        }

        setCellSelected(indexOfArray);
    }

    function onChangeDifficulty(e) {
        let value = e.target.value;
        setDifficulty(value);
        setTimeout(() => _createNewGame(value), 200);
    }

    function onClickNumber(number) {
        if (fastMode) {
            setNumberSelected(number)
        } else if (cellSelected !== -1) {
            _userFillCell(cellSelected, number);
        }
    }

    function onClickUndo() {
        if (history.length) {
            let tempHistory = history.slice();
            let tempArray = tempHistory.pop();
            setHistory(tempHistory);
            ChangeTheArray(tempArray);
        }
    }

    function ChangeTheArray(array) {
        setGameArray(array);
        localStorage.setItem('temporaryInitArray', array);
    }

    function onClickErase() {
        if (cellSelected !== -1 && gameArray[cellSelected] !== '0') {
            _fillCell(cellSelected, '0');
        }
    }

    function onClickHint() {
        if (cellSelected !== -1) {
            _fillCell(cellSelected, solvedArray[cellSelected]);
        }
    }

    function onClickMistakesMode() {
        setMistakesMode(!mistakesMode);
    }

    function onClickFastMode() {
        if (fastMode) {
            setNumberSelected('0');
        }
        setCellSelected(-1);
        setFastMode(!fastMode);
    }

    function onClickOverlay() {
        setOverlay(false);
        _createNewGame();
    }

    function onCellPress(e) {
        if (["1", "2", "3", "4", "5", "6", "7", "9", "8"].includes(e.key)) {
            console.log(e.key);
            onClickNumber(e.key);
        }

        if (["Delete", "Backspace"].includes(e.key)) {
            console.log(e.key);
            onClickErase();
        }
    }

    useEffect(() => {
        const puzzle = localStorage.getItem('temporaryInitArray');
        if (!puzzle) {
            _createNewGame();
        } else {
            loadExistingGame();
        }
    }, []);

    useEventListener('keydown', onCellPress)
    return (
        <>
            <div className={overlay ? "container blur" : "container"}>

                <div className="innercontainer">
                    <GameSection
                        onClick={(indexOfArray) => onClickCell(indexOfArray)}
                    />
                    <StatusSection
                        onClickNumber={(number) => onClickNumber(number)}
                        onChange={(e) => onChangeDifficulty(e)}
                        onClickUndo={onClickUndo}
                        onClickErase={onClickErase}
                        onClickHint={onClickHint}
                        onClickMistakesMode={onClickMistakesMode}
                        onClickFastMode={onClickFastMode}
                        onClickNewGame={onClickNewGame}
                    />
                </div>
            </div>
            <div className={overlay
                ? "overlay overlay--visible"
                : "overlay"
            }
                onClick={onClickOverlay}
            >
                <h2 className="overlay__text">
                    You <span className="overlay__textspan1">solved</span> <span className="overlay__textspan2">it!</span>
                </h2>
            </div>
        </>
    );
}

// Hook
function useEventListener(eventName, handler) {
    let element;
    if (typeof window !== 'undefined') {
        element = window;
    }
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On 
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = event => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};