import React from 'react';
import { Game } from './game';
import { SudokuProvider } from './sudokuContext';

export const Sudoku = () => {
  return (
    <SudokuProvider>
      <Game />
    </SudokuProvider>
  );
}
